import { Container, Typography } from '@mui/material'
import logo from "../../../src/logo.png";
import styles from './Home.module.css';
import { Fade } from "react-awesome-reveal";
import Footer from './Footer';
import Divider from '@mui/material/Divider';

const Home = () => {

    return (

        <div className={styles.mainContainer}>
            <Container maxWidth={false}>
                <Fade duration="2000">
                    <div className={styles.flex}>
                        <img src={logo} className={styles.logo} alt="NFT Games" />
                    </div>

                </Fade>
                <Fade duration="2000">
                    <Typography variant="h3" color="white" align="center" className={styles.overview} sx={{ margin: '2rem 0 2rem' }}>
                        Overview
                    </Typography>
                </Fade>
                <Fade duration="2000">
                    <Typography variant="body1" align="center" sx={{ width: '80%', margin: 'auto' }}>
                        Games Comming Soon
                        <br /><br />
                        Join our initiative Now!
                    </Typography>
                </Fade>

                <Divider sx={{ width: '90%', margin: '2rem' }} />



            </Container>
            <Footer />
        </div>

    )
}
export default Home;