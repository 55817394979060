import { Divider, IconButton, List,  } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Collapse from '@mui/material/Collapse';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { DrawerHeader } from '../../App';
import { darkTheme as theme } from '../../theme';

const drawerWidth = 320;

const openedMixin = (theme) => ({
    width: drawerWidth,
    //background: "linear-gradient(180.48deg,rgba(115,70,215,1) -26.15%,rgba(57,35,81,1) 189.84%)",
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    background: "linear-gradient(180.48deg,rgba(115,70,215,1) -26.15%,rgba(57,35,81,1) 189.84%)",
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(2)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',

        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const Sidebar = ({ open, handleDrawer }) => {
    // const theme = useTheme();
    const [openM, setOpenM] = useState(false);
    const [openM2, setOpenM2] = useState(false);
    const handleClickM = () => {
        setOpenM(!openM);
    };
    const handleClickM2 = () => {
        setOpenM2(!openM2);
    };
    return (
        <Drawer variant="permanent" open={open} >
            <DrawerHeader>
                <IconButton onClick={handleDrawer}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                <ListItemButton component={RouterLink} to="/">
                    <ListItemIcon>
                        <SendIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItemButton>

                <ListItemButton onClick={handleClickM} component={RouterLink} to="/CommingSoon">
                <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Games" />
                    {openM ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openM} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                        <ListItemButton component={RouterLink} to="/CommingSoon">
                            <ListItemIcon>
                             <SendIcon />
                            </ListItemIcon>
                        <ListItemText primary="Comming Soon" />
                        </ListItemButton>

                       

                    </List>
                </Collapse>

                <Divider />

                <ListItemButton component={RouterLink} to={{ pathname: "/" }} target="_blank" >
                    <ListItemIcon>
                        <SendIcon />
                    </ListItemIcon>
                    <ListItemText primary="Docs / Whitepaper" />
                </ListItemButton>

            </List>
        </Drawer>
    )
}

export default Sidebar;