import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { darkTheme } from './theme';
import { Box, CssBaseline, styled, ThemeProvider } from '@mui/material';
import { useState } from 'react';
import MainAppbar from './components/Appbar/MainAppbar';
import Sidebar from './components/Sidebar/Sidebar';

import Home from './pages/Home/Home';


export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  minHeight: '80px',
}));

function App() {
  const [open, setOpen] = useState(true);
  const handleDrawer = () => {
    setOpen(!open);
  };

  const mainContainerStyle = {
    // background: '#212121',
    // color: '#fafafa',
    flexGrow: 1,
    overflowX: 'hidden'
  }

  return (

    <Router>
      <ThemeProvider theme={darkTheme}>
        <Box sx={{ display: 'flex', }}>
          <CssBaseline />
          <MainAppbar open={open} handleDrawer={handleDrawer} />
          <Sidebar open={open} handleDrawer={handleDrawer} />
          <Box component="main" sx={mainContainerStyle}>
            <DrawerHeader />
            <Routes>
              <Route exact element={<Home />} path="/" />

            </Routes>
          </Box>
        </Box>
      </ThemeProvider>
    </Router>

  );
}

export default App;
