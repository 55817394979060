import { Box, IconButton, styled, Toolbar } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../logo.png';

const drawerWidth = 320;
const styles = {
  content: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    //color: "#041836",
    marginTop: "85px",
    padding: "10px",
  },
  subcontent: {
    display: "flex",
    padding: " 3rem",
    width: "100%",
  },
  header: {
    display: "flex",
    zIndex: 1,
    width: "100%",
    background: "transparent",
    alignItems: "center",
    fontFamily: "Aclonica",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "0 10px",
    boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
  },
  headerRight: {
    display: "flex",
    gap: "20px",
    alignItems: "right",
    fontSize: "15px",
    fontWeight: "600",
    margin: "0 20px",
  },
  logo: {
    color: "#1fc7d3",
    //color: "#7346d7",
    alignItems: "left",
    fontSize: "1.75rem",
    fontFamily: "Aclonica",
    fontWeight: "400",
    marginLeft: "0.8rem"
  },
  logo2: {
    color: "#1fc7d3",
    //color: "#7346d7",
    alignItems: "center",
    fontSize: "0.95rem",
    fontFamily: "Aclonica",
    fontWeight: "200"
  },
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MainAppbar = ({ open, handleDrawer }) => {
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar sx={{ minHeight: '80px' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
          edge="start"
          sx={{
            marginRight: '1px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: "center", background: "transparent", width: "100%", }}>
          <img src={logo} alt="Logo" height='80px' />
          <div style={styles.logo}>
          Salient Games Studio
            <div style={styles.logo2}>
              NFT Games
            </div>
          </div>
        </Box>
        <div style={styles.headerRight}>
          {/* <Chains />
                <Account /> */}
        </div>
      </Toolbar>
    </AppBar>
  )
}
export default MainAppbar;
